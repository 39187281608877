.suitedCell {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #f59694;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 2;
}

.offsuitCell {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #6fbcff;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 2;
}

.comboCell {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #b9b9b9;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}
.pocketPairCell {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #b9b9b9;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}

.suitedCellSelected {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #d03234;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 2;
}

.offsuitCellSelected {
  font-family: Lato, Arial, sans-serif;
  color: black;
  background-color: #2b62b4;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 2;
}

.comboCellSelected {
  font-family: Lato, Arial, sans-serif;
  color: white;
  background-color: #747474;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}
.pocketPairCellSelected {
  font-family: Lato, Arial, sans-serif;
  color: white;
  background-color: #747474;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
}

.button {
  background-color: #e7e7e7;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
}
.buttonSelected {
  background-color: #555555;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
}
