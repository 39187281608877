.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #273238;
  height: 56px;
  font-family: Verdana, Geneva, DejaVu, Sans, Bitstream, Vera, Sans, Liberation,
    Sans, Verdana, Ref, sans-serif;
  font-size: 1em;
  font-weight: 600;
  z-index: 9999;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.spacer {
  flex: 0;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: 200px;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #fa923f;
}

@media (max-width: 768px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}
